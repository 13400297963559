import React, { useCallback, useState } from "react";
import { useParams, useSearchParams } from "react-router-dom";
import axios from "axios";
import { DocumentUpload } from "@simplecitizen/gdl-react-ui-components";
import { CameraFacingMode } from "@regulaforensics/vp-frontend-document-components";
import { base64ToFile } from "src/utils/utils";




type ErrorObject = {
  [key: string]: {
    en: string;
  };
};

export type FieldError = {
  fieldName: string;
  error: string;
};

// const MAX_UPLOAD_ATTEMPTS = parseInt(
//   process.env.REACT_APP_MAX_UPLOAD_ATTEMPTS || "3",
// );

const API_BASE_URL =
  process.env.REACT_APP_API_BASE_URL || "https://localhost:5003/api/workright";

const DocumentUploadMobileHandoff = () => {
  const [searchParams] = useSearchParams();
  const [faceMisMatch,setFaceMisMatch] = useState(false)
  const startScreen = searchParams.get("startScreen");
  const juriCode = searchParams.get("juri_code")
  const facialComparison = searchParams.get("facialComparison");
  const documentName = searchParams.get("documentName");
  const color = searchParams.get("color");

  /**
   * Converts a string by removing "data.", dots, and hyphens, and converting to lowercase.
   * @param {string} str - The string to convert.
   * @returns {string} - The processed string in lowercase without "data.", dots, or hyphens.
   */
  function toLowerNoSeparators(str: string) {
    return str
      .replace(/^data[.-]/, "")
      .replace(/[.-]/g, "")
      .toLowerCase();
  }

  /**
   * Converts an object of errors to an array of FieldError objects.
   * @param {ErrorObject} errors - The error object to convert.
   * @returns {FieldError[]} - Array of FieldError objects.
   */
  function convertErrorsToFieldErrors(errors: ErrorObject): FieldError[] {
    return Object.keys(errors).map((key) => ({
      fieldName: toLowerNoSeparators(key),
      error: errors[key].en,
    }));
  }

  const { checkId, docId, sessionId } = useParams<{
    checkId: string;
    docId: string;
    sessionId: string;
  }>();

  const [serverErrors, setServerErrors] = useState<string[] | null>([]);
  const [startOnRegulaUploader, setStartOnRegulaUploader] = useState(true);
  const [documentUploaded, setDocumentUploaded] = useState(false);
  const [fieldErrors, setFieldErrors] = useState<FieldError[]>([]);
  const [forceSubmit, setForceSumit] = useState(false);

  const handleFileChange = useCallback( async (files: File[]): Promise<any> => {
    try {
      const formData = new FormData();
      formData.append("file", files[0]);
      formData.append("key", "front");
  
      const config = {
   
        headers: {
          accept: "application/json",
          "Content-Type": "multipart/form-data",
        },
      };
  
      const { data } = await axios.post(
        `${API_BASE_URL}/v1/checks/${checkId}/document-collectors/${docId}/images`,
        formData,
        config
      );
  

      return Promise.resolve(data);
      // setFileUploadError(null);
    } catch (error) {
      return Promise.reject("An error occurred while uploading the file");
      // console.error("An error occurred while uploading the file", error);
    } finally {
    }
  },[checkId,docId]);

  const verifyDocument = async ({ docFrontSideImage,selfieImage }: any) => {
    setForceSumit(false);
    setServerErrors([]);
    setFieldErrors([]);
    setFaceMisMatch(false)
    const newDocument = {
      images: [{ format: "base64", key: "front", source: docFrontSideImage },            {
        format: "base64",
        key: "selfie",
        source: selfieImage,
      },],
      relationships: [
        {
          type: "mobile-handoff-session",
          id: sessionId,
        },
      ],
    };

    try {
      await axios.post(
        `${API_BASE_URL}/v1/checks/${checkId}/document-collectors/${docId}/Validate`,
        newDocument
      );
      setDocumentUploaded(true);
      return Promise.resolve("success");
      //   setDocKey(response.data._id);
    } catch (error: any) {
      const document = error?.response?.data;
      if(error.response){
        if(error?.response?.data?.errors){
          if(error?.response?.data?.errors["images.selfie"]){
            setFaceMisMatch(true)
          }
          // console.log({ errors: error?.response?.data?.errors["images.selfie"] })
        }
      }
      if (document && document?.document) {
     
         if(juriCode?.trim().toLowerCase() !== 'gbr'){
          setForceSumit(true);
         }

      }
      if (error.response.data.errors) {
        const field_errors = convertErrorsToFieldErrors(
          error.response.data.errors
        );
        console.log({ field_errors });
        setFieldErrors(field_errors);
        return Promise.reject("error");
      }
      return Promise.reject(error.response.data.message || "An error occurred");

      //   handleApiErrors(error.response.data);
    }
  };

  const forceDocument = async ({ docFrontSideImage }: any) => {

    setServerErrors([]);
    setFieldErrors([]);
 
    try {
      const base64Image = `data:image/png;base64,${docFrontSideImage}`;
      const file = base64ToFile(
        base64Image,
        "forced-document.png",
        "image/png"
      );
      const imageObject = await handleFileChange([file]);
      //
      const newDocument = {
        images: [imageObject],
        status: "created",
        relationships: [
          {
            type: "mobile-handoff-session",
            id: sessionId,
          },
        ],
      };
    await axios.post(
        `${API_BASE_URL}/v1/checks/${checkId}/document-collectors/${docId}/Validate`,
        newDocument
      );
      setDocumentUploaded(true);
      return Promise.resolve("success");
      //   setDocKey(response.data._id);
    } catch (error: any) {
      if (error.response.data.errors) {
        const field_errors = convertErrorsToFieldErrors(
          error.response.data.errors
        );

        setFieldErrors(field_errors);
        return;
      }
      return;

      //   handleApiErrors(error.response.data);
    }
  };

  return (
    <div className="h-full w-full flex items-center">
      <DocumentUpload
      faceMisMatch={faceMisMatch}
        forceSubmit={forceSubmit}
        facialComparison={facialComparison === "true"}
        uploadAttempts={3}
        regulaStartScreen={startScreen === "true"}
        // regulaStartScreen={true}
        onStoreRegulaDocument={async ({ docFrontSideImage }, rawDocument) => {
          const base64Data = docFrontSideImage.split(",")[1];
          // setUploadedImage(base64Data);
          await verifyDocument({ docFrontSideImage: base64Data });
          setStartOnRegulaUploader(false);
        }}
        onForceRegulaDocument={async ({ docFrontSideImage }, rawDocument) => {
          const base64Data = docFrontSideImage.split(",")[1];
          // setUploadedImage(base64Data);
          await forceDocument({ docFrontSideImage: base64Data });
          setStartOnRegulaUploader(false);
          setDocumentUploaded(true);
        }}
        startOnRegulaUploader={startOnRegulaUploader}
        isDocumentUploadedOnMobile={documentUploaded}
        regula_settings={{
          regulaLogo: false,
          changeCameraButton: true,
          cameraMode: "environment" as CameraFacingMode,
          multipageProcessing: true,
          closeButton: false,
        }}
        fieldErrors={fieldErrors}
        onReplace={() => {}}
        verifyDocument={async ({ docFrontSideImage,selfieImage  }, rawData) => {
          const base64Data = docFrontSideImage.split(",")[1];
          // setUploadedImage(base64Data);
          await verifyDocument({ docFrontSideImage: base64Data,selfieImage });
        }}
        verifyOnProcessingDocument={false}
        documentReaderProcessParams={{
          returnUncroppedImage: false,
        }}
        title={`Upload ${documentName}`}
        documentName={`${documentName}`}
        onRemoveDocument={() => {
          // if (document._subtype === "identity") {
          //   return removeDocument(document, "identityDocuments");
          // }
          // removeDocument(document, "nonIdentityDocuments");
        }}
        // onFileChange={async (files) => {
        //   await handleFileChange(files);
        // }}
        // uploadProgress={uploadProgress}
        onStoreDefaultDocument={async (docData) => {
          console.log({ docData });
          // await submitNonIdDoc(docData);
        }}
        serverErrors={serverErrors!}
        identityType={"identity"}
        color={
        color || "#14BDF3"
        }
      />
    </div>
  );
};

export default DocumentUploadMobileHandoff;
