import {
  DatePicker,
  LocationSearch,
  TextInput,
  Title,
} from "@simplecitizen/gdl-react-ui-components";
import {  formatISO } from "date-fns";
import { useMemo, useState } from "react";
import { FieldErrors, useController, UseFormRegister } from "react-hook-form";
import { useAppSelector } from "src/hooks/redux-hooks";
import { parceDateStringToDate , validateInputDate } from "src/utils/utils";

interface ExperianQuestionsProps {
  control: any;
  register: UseFormRegister<any>;
  errors: FieldErrors<any>
  setExperianFullAddress: React.Dispatch<any>
}

const ExperianQuestions = ({ control,register,errors,setExperianFullAddress }: ExperianQuestionsProps) => {
  const profile = useAppSelector((state) => state.profileData);
  const [dateValue,setDateValue] = useState(new Date(profile.profile.birth.date))
  const { field: primaryResidentialAddressField } = useController({
    name: "googleResidentialAddress",
    control,
    rules:{
      required:'Primary residential address is required',
  
    }
  });

  const { field: dateOfBirthField } = useController({
    name: "experianDateOfBirth",
    control,
    defaultValue: profile.profile.birth.date ? formatISO(parceDateStringToDate(profile.profile.birth.date)!,{ representation: 'date' })   : '',
    rules:{
      required:'Date of birth is required',
      validate: (value)=>  validateInputDate(value,true),
    }
  });
  const fields = useMemo(() => {
    return [
      {
        label:'First Name',
        error_message:errors['experianFirstName']?.message,
        type:'text',
        defaultValue:profile.profile.name.firstName,
        ...register('experianFirstName',{
          required:`First name is required` ,
        }),
    
        required:true
              },
      {
        label:'Last Name',
        error_message:errors['experianLastName']?.message,
        required:true,
        type:'text',
        defaultValue:profile.profile.name.lastName,
        ...register('experianLastName',{
          required:`Last name is required` ,
        

        })
              },
      {
        label: "Date of Birth",
        required:true,
        name: "experianDateOfBirth",
        error_message:errors['experianDateOfBirth']?.message,
        type: "date",
        disableFutureDates: true,
        value: dateValue
       ,
          onChange: (e:any)=>{
     
            dateOfBirthField.onChange(e.target.value);
          },
        onDateChange: (date: Date) => {
          const formattedDate = formatISO(date,{ representation: 'date' })
          setDateValue(date)
           dateOfBirthField.onChange(formattedDate);
        },
      },
   
      {
        label: "Primary residential address",
        error_message:errors['googleResidentialAddress']?.message,
        required:true,
        type: "location-search",
        onSelectLocation: (value: any) => {
          primaryResidentialAddressField.onChange(
            value.geo_results[0].formatted_address
          );
        },
      },
   
    ];
  }, [dateOfBirthField, primaryResidentialAddressField,profile,register,errors,dateValue]);
  const client = useAppSelector(
    (state) => state.questionnaire_data.questionnaire?.client
  );

  return (
    <div className="w-full mt-6">
      <Title className="mt-3 mb-3">Experian Questions</Title>
      <div className="flex flex-col">
        {fields.map(({ name, type, ...field }) => (
          <div key={name} className="">
            {type === "text" && (
              <TextInput
                className=""
                labelClassName="font-normal "
                name={name}
                {...field}
              />
            )}
            {type === "location-search" && (
              <LocationSearch
              onAddressChange={
                (address:any) => setExperianFullAddress(address)
              }
              showAddressDetails
                color={client?.branding?.primaryColor || "#0A71C7"}
                key={name}
                className="mb-2"
                 addressBlockLabelsClassName="font-normal"
                labelClassName="font-normal"
                onClear={()=>{
                  primaryResidentialAddressField.onChange(
             ""
                  )
                }}
                
                {...field}
              />
            )}
            {type === "date" && (
              <DatePicker
              onChange={field.onChange}
              error_message={field.error_message}
                disableFutureDates={field.disableFutureDates}

                className=""
                color={client?.branding?.primaryColor || "#0A71C7"}

                labelClassName="font-normal"
                onDateChange={field.onDateChange!}
                value={(profile.profile.birth.date ? parceDateStringToDate(profile.profile.birth.date) : new Date()) as Date}
                label={field.label}
                placeholder="DD/MM/YYYY"
                required={field.required}
                initialValue={profile.profile.birth.date ? parceDateStringToDate(profile.profile.birth.date) : undefined}
              />
            )}
          </div>
        ))}
      </div>
    </div>
  );
};

export default ExperianQuestions;
