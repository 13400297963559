import {

  TextInput,
  Title,
  TextArea,
} from "@simplecitizen/gdl-react-ui-components";

import { FieldErrors, UseFormRegister } from "react-hook-form";
import DateInput from "./DateInput";

import SelectInput from "../select-input/SelectInput";

interface RequiredDataProps {
  selectedStatusType: StatusType | null;
  register: UseFormRegister<any>;
  control: any;
  errors: FieldErrors<any>
}

const RequiredData = ({
  selectedStatusType,
  register,
  control,
  errors
}: RequiredDataProps) => {

  return (
    <div>
      <Title className="mt-3 mb-3">Required information</Title>
      <div className="flex flex-col">
        {selectedStatusType?.dataCollectors?.map((dataCollector) => {
          return (
            <div key={dataCollector._id}>
              {(dataCollector._subtype === "select" ||
                dataCollector._subtype === "optionset") && (
                <SelectInput required={dataCollector.required}  errors={errors} control={control} dataCollector={dataCollector} />
              )}
              {dataCollector._subtype === "text" && (
                <TextInput
                 error_message={errors[dataCollector?.key]?.message}
                 required={dataCollector.required}
                  labelClassName="font-normal normal-case"
                  label={dataCollector.label}
                  {...register(dataCollector.key,{
                     required: !dataCollector.required ? false :   dataCollector.label ? `${dataCollector.label} is required` : 'This field is required',
                  

                  })}
                />
              )}
              {dataCollector._subtype === "sharecode" && (
                <TextInput
                required={dataCollector.required}
                toolTip={
                  <div>
                    If you do not already have a valid share code, please go to <a target='_blank' rel="noreferrer" href="https://www.gov.uk/prove-right-to-work" className="text-[#1D85DB]">Prove your right to work to an employer</a>   to get a share code before inserting this into the box below.
                  </div>
                }
                 error_message={errors[dataCollector?.key]?.message}
                  labelClassName="font-normal normal-case"
                  label={dataCollector.label}
                  {...register(dataCollector.key,{
                     required: !dataCollector.required ? false :   dataCollector.label ? `${dataCollector.label} is required` : 'This field is required',
                     pattern: {
                      value: /^[Ww]/, // Only checks if it starts with "W" or "w"
                      message: "Must start with 'W' or 'w'",
                
                    },
                    maxLength: {
                      value: 9,
                      message: "Cannot exceed 9 characters",
                    },

                  })}
                  onPaste={(e:any) => {
                    const pastedText = e.clipboardData.getData("text");
                    if (pastedText.length > 9) {
                      e.preventDefault();
                    }
                  }}
                  
                />
              )}
              {dataCollector._subtype === "date" && (
                <DateInput required={dataCollector.required} errors={errors} control={control} dataCollector={dataCollector}  />
              )}
              {dataCollector._subtype === "textarea" && (
                <TextArea
                  labelClassName="font-normal normal-case"
                  error_message={errors[dataCollector?.key]?.message}
                  required={dataCollector?.required}
                  label={dataCollector.label}
                  {...register(dataCollector.key,{
                     required: !dataCollector.required ? false : dataCollector.label ? `${dataCollector.label} is required` : 'This field is required',


                  })}
                />
              )}
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default RequiredData;
